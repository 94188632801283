import React from "react";
import CovidHeader from "./Covid-Header/index";
import CovidPageContent from "./Covid-Page";

const CovidPage = () => {
    return (
        <React.Fragment>
            <CovidHeader />
            <CovidPageContent />
        </React.Fragment>
    )
} 

export default CovidPage;