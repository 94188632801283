import React from "react";
import './index.css';

const CovidHeader = () => (
    <div className="header-image">
        <div className="covid-text">
            <div className="covid-btn">
                COVID-19 Safety precautions
            </div>
        </div>
    </div>
)

export default CovidHeader;