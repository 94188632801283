import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../../lang";
import TaxiImage from "../../../images/covid/taxi.jpeg";
import "./index.css";

const CovidPageContent = () => {
	const {
		translations: { covid },
	} = useContext(LanguageContext);
	return (
		<section id="covid-content">
			<div className="covid-content-container">
				<div className="image-container">
					<img src={TaxiImage} alt="" className="taxi-image" />
				</div>
				<div className="content-container text">
					<div>{covid.para1}</div>
					<ul className="list-item-container">
						<li>{covid.listItem1}</li>
						<li>{covid.listItem2}</li>
						<li>{covid.listItem3}</li>
						<li>{covid.listItem4}</li>
						<li>{covid.listItem5}</li>
						<li>{covid.listItem6}</li>
					</ul>
					<div>{covid.para2}</div>
					<ul className="list-item-container">
						<li>{covid.listItem7}</li>
						<li>{covid.listItem8}</li>
						<li>{covid.listItem9}</li>
						<li>{covid.listItem10}</li>
						<li>{covid.listItem11}</li>
						<li>{covid.listItem12}</li>
						<li>{covid.listItem13}</li>
					</ul>
					<div>{covid.para3}</div>
					<ul className="list-item-container symptops-list">
						<li>{covid.listItem14}</li>
						<li>{covid.listItem15}</li>
						<li>{covid.listItem16}</li>
						<li>{covid.listItem17}</li>
						<li>{covid.listItem18}</li>
					</ul>
					<div>{covid.para}</div>
					<br />
					<div>{covid.para4}</div>
					<li>
						<b className="heading">{covid.heading1}: </b>
						{covid.headingPara1}
					</li>
					<li>
						<b className="heading">{covid.heading2}: </b>
						{covid.headingPara2}
					</li>
					<br />
					<h5 className="heading">{covid.para5}</h5>
					<ul className="list-item-container">
						<li>{covid.listItem19}</li>
						<li>{covid.listItem20}</li>
						<li>{covid.listItem21}</li>
						<li>{covid.listItem22}</li>
						<li>{covid.listItem23}</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default CovidPageContent;
